import styled, { keyframes } from 'styled-components'
import { image2 } from '../../assets/index'


export const BreakContainer = styled.div`
    height: 800px;
    width:100%;
    background: url(${image2});
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items:center;
    button{
        margin-top: 30px;

    }
    @media screen and (max-width: 400px) {
        height: 50vh;
    }
`

export const BreakDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;

`

export const BreakTitle = styled.h1`
    display: flex;
    font-weight: bolder;
    font-size: 3.5vw;
`
