import React from 'react'
import Img from "gatsby-image"
import {
    SvgContainer,
    PointerSvg,
    DoorSvg,
    WindowSvg,
    AboutStyle,
    AboutContainer,
    Title,
    Description,
    Description_image,
    Products,
    Product
} from './AboutElements'
import { texts } from '../../texts'
import { Link } from 'gatsby'
import { GlobalContext } from "../GlobalContext.js"

export default function About({logo2}) {
    const { language } = React.useContext(GlobalContext)

    return (
        <AboutStyle>
            <AboutContainer
               
            >
                <div
                 data-sal="slide-up"
                 data-sal-delay="1200"
                 data-sal-easing="linear"
                >
                <Title>
                    <PointerSvg />
                    <h1>{texts.about.title[language]}</h1>
                </Title>
                <Description>
                        {texts.about.description[language]}
                    <Description_image>
                        <Img fluid={logo2} />
                    </Description_image>
                </Description>
                </div>
                <Products>
                    <Link 
                        to='/gallery'
                        state={{path:'image'}}
                        data-sal="flip-up"
                        data-sal-delay="500"
                        data-sal-easing="linear"
                    >
                        <Product>
                            <div>
                            <SvgContainer>
                                <WindowSvg/>
                            </SvgContainer>
                            <p>
                                {texts.about.product1[language]}
                            </p>
                            </div>
                        </Product>
                    </Link>
                    <Link 
                        to='/gallery' 
                        state={{path:'door'}}
                        data-sal="flip-up"
                        data-sal-delay="800"
                        data-sal-easing="linear"
                    >
                        <Product>
                            <div>
                                <SvgContainer>
                                    <DoorSvg/>
                                </SvgContainer>
                                <p>
                                    {texts.about.product2[language]}
                                </p>
                            </div>    
                        </Product>
                    </Link>
                </Products>
            </AboutContainer>
        </AboutStyle>
    )
}
