import React , { useContext } from 'react'
import Img from "gatsby-image"
import {
    ClientSvg,
    GuarantySvg,
    PriceSvg,
    TimeSvg,
    ExpertSvg,
    SerivceImageContainer,
    Service,
    ServicesContainer,
    Container
}from '../Services/ServicesElements'
import {
    Title,
    PointerSvg,
    AboutContainer,
    AboutStyle
}from '../About/AboutElements'
import { texts } from '../../texts'
import { GlobalContext } from "../GlobalContext.js"

const svgs = [
    <ClientSvg />,
    <ExpertSvg />,
    <GuarantySvg />,
    <TimeSvg />,
    <PriceSvg />
]

export default function Services({img}) {
    const { language } = useContext(GlobalContext)

    return (
        <AboutStyle>
            <AboutContainer>
                <Title>
                    <PointerSvg />
                    <h1>{texts.services.title[language]}</h1>
                </Title>
                <Container>
                    <ServicesContainer language={language}>
                        {texts.services.services.map((e,index) => (
                            <Service language={language} key={`${e[language]}_${index}`}>
                                <div>
                                    {svgs[index]}
                                </div>
                                <p>{e[language]}</p>
                            </Service>
                        ))}
                    </ServicesContainer>
                    <SerivceImageContainer  language={language}>
                    <div
                        data-sal="slide-left"
                        data-sal-delay="500"
                        data-sal-easing="linear"
                    >
                        <Img 
                            fluid={img} 
                            
                        />
                    </div>
                    </SerivceImageContainer>

                </Container>
            </AboutContainer>
        </AboutStyle>
        
    )
}
