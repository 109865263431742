import styled from "styled-components";





export const ShowcaseContainer = styled.div`
    display:grid;
    grid-gap: 10px;
    grid-template-columns:  repeat(auto-fill,minmax(250px,1fr));
    grid-auto-rows:minmax(50px, auto);
    width: 100%;
    margin: 50px auto;
    padding: 0 32px;
    img{
        transition:transform 0.6s ease !important;
    }
    img:hover{
        transform:scale(1.1);
    }
    &:nth-child(5){
            grid-column-end: span 2;
    }
    &:nth-child(9){

    grid-column-end: span 2;
    grid-row-start: 4;
    }
    @media screen and (max-width: 400px) {
        display: flex;
        flex-direction: column;
    }
`

export const ShowcaseItem = styled.div`
    display: flex;
    flex-direction: column;
    &:nth-child(5){
        grid-column-end: span 2;
    }
    &:nth-child(9){
    
        grid-column-end: span 2;
        grid-row-start: 4;
    }
    div{
        height:100%;
    }
    @media screen and (max-width: 400px) {
        width: 200px;
    }

`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(({bg}) => bg)};
    margin: 40px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    color: white;
    width: max-content;
    margin: auto;
    ::after{
            content: '';
            position: absolute;
            top:0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background-color: #FFFFFF17;
            z-index: 1;
            border-radius: 10px;
            opacity: 0;
            transition: opacity .3s ease-out;
    }
    &:hover{
        ::after{
            content: '';
            position: absolute;
            top:0px;
            left: 0px;
            height: 100%;
            width: 100%;
            z-index: 2;
            border-radius: 10px;
            opacity: 1;
        }
    }
    @media screen and (max-width: 400px) {
      margin:10px 20px;
      padding: 10px;
      font-size: 2.5vh;
    }
`