import React  from "react"
import { graphql } from "gatsby"
import '../styles/global.css'
import Home from '../components/Home'
import About from "../components/About"
import Services from "../components/Services"
import Break from "../components/Break"
import Showcase from "../components/Showcase"
import Layout from "../layout"




export function findFromImages(name,data,find=true){
  let found

  if(find){
     found = data.allFile.edges.find( 
      el => el.node.name.includes(name) 
    )   
  }else {
     found = data.allFile.edges.filter( 
      el => el.node.name.includes(name)
    )   
  }

  let arr = []
  if(found?.length > 0 ){
    found.forEach( element =>   arr.push(element.node.childImageSharp.fluid) );
    found = [...arr]
  } else {
    found = found.node.childImageSharp.fluid
  }

  return found
}




const IndexPage = ({data}) => {

  return (
      <>
        <Home logoImg = {findFromImages('logo1',data)} />
        <About logo2 = {findFromImages('logo2',data)} />
        <Services img={findFromImages('worker',data)} />
        <Break  />
        <Showcase images = {[
          findFromImages('image4',data),
          findFromImages('image14',data),
          findFromImages('image13',data),
          findFromImages('image36',data),
          findFromImages('image9',data),
          findFromImages('image5',data),
          findFromImages('image6',data),
          findFromImages('image7',data),
          findFromImages('image8',data),
          findFromImages('image38',data),
        ]} />
      </>
  )
}


export const query = graphql`
    query MyQuery {
        allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
          edges {
              node {
              id
              name
              childImageSharp {
                  fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                  }
              }
              }
          }
        }
    }
`
IndexPage.Layout = Layout
export default IndexPage
