import React , { useContext } from 'react'
import { Link } from 'gatsby'
import {
    HomeContainer,
    Headlines,
    HomeH1,
    HomeH2,
    Hero,
    ButtonsContainer,
    Button
} from './HomeElements'
import { colors } from '../../theme'
import { texts } from '../../texts'
import { GlobalContext } from "../GlobalContext.js"

export default function Home() {
    const { language } = useContext(GlobalContext)
    return (
        <HomeContainer>
            <Hero>
                    <Headlines>
                        <HomeH1 key={`index_${texts.home.h1[language]}`} >{texts.home.h1[language]}</HomeH1>
                        <HomeH2 key={`index_${texts.home.h2[language]}`} >{texts.home.h2[language]}</HomeH2>
                    </Headlines>
                    <ButtonsContainer>
                        <Link to={texts.home.button1.link}>
                            <Button key={`index_${texts.home.button1[language]}`} bg={colors.green}>
                                    {texts.home.button1[language]}
                            </Button>
                        </Link>
                        <Link to={texts.home.button2.link}>
                            <Button key={`index_${texts.home.button2[language]}`} bg={colors.dark} >
                                    {texts.home.button2[language]}
                            </Button>
                        </Link>

                    </ButtonsContainer>
            </Hero>
        </HomeContainer>   
    )
}
