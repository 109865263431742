import React from 'react'
import Img from 'gatsby-image'
import {
    ShowcaseContainer,
    ShowcaseItem,
    ShowcaseStyle,
    Button
} from './ShowcaseElements'
import {
    Title,
    PointerSvg,
    AboutContainer,
    AboutStyle,
}from '../About/AboutElements'
import { texts } from '../../texts'
import { colors } from '../../theme'
import { Link } from 'gatsby'
import { GlobalContext } from "../GlobalContext.js"

export default function Showcase({images}) {
    const { language } = React.useContext(GlobalContext)

    return (
        <AboutStyle>
            <AboutContainer>
                <Title>
                    <PointerSvg />
                    <h1>{texts.showcase.title[language]}</h1>
                </Title>
                <ShowcaseContainer style={{maxWidth:1000}}>
                    {images.map((image, index) => (
                        <ShowcaseItem  key = {`index_${index}`}>
                            <div
                                 data-sal="slide-down"
                                 data-sal-delay="500"
                                 data-sal-easing="linear"
                            >
                                <Img
                                fluid = {image} 
                                alt ='UPVC Image'
                                />
                            </div>
                            
                        </ShowcaseItem>  
                    ))}
                </ShowcaseContainer>
                <Link to='/gallery'>
                    <Button bg={colors.dark}>{texts.home.button2[language]}</Button>
                </Link>
            </AboutContainer>
        </AboutStyle>
    )
}
