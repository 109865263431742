import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { image1 } from '../../assets/index'
import { colors } from '../../theme'


export const HomeContainer = styled.div`
    height: 100vh;
    width:100%;
    background: url(${image1});
    background-size: cover;
`

export const Hero = styled.div`
    position: absolute;
    top:0;
    width:100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background-color:${colors.dark2};
    z-index: 1;
    color:white;
`

export const Headlines = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(({bg}) => bg)};
    margin: 40px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    ::after{
            content: '';
            position: absolute;
            top:0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background-color: #FFFFFF17;
            z-index: 1;
            border-radius: 10px;
            opacity: 0;
            transition: opacity .3s ease-out;
    }
    &:hover{
        ::after{
            content: '';
            position: absolute;
            top:0px;
            left: 0px;
            height: 100%;
            width: 100%;
            z-index: 2;
            border-radius: 10px;
            opacity: 1;
        }
    }
    @media screen and (max-width: 400px) {
      margin:10px 20px;
      padding: 10px;
      font-size: 2.5vh;
    }
`


export const HomeH1 = styled.h1`
    margin: auto;
    display: flex;
    font-weight: bolder;
    /* font-size: 30px; */
    margin: 20px;
`

export const HomeH2 = styled.h2`
    margin: auto;
    display: flex;
    /* font-size: 20px; */
    margin: 20px;
`


