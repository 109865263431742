import React from 'react'
import{
    BreakContainer,
    BreakDescription,
    BreakTitle
} from './BreakElements'
import {
    Button
} from '../Home/HomeElements'
import { texts } from '../../texts'
import { colors } from "../../theme"
import { Link } from 'gatsby'
import { GlobalContext } from "../GlobalContext.js"

export default function Break() {
    const { language } = React.useContext(GlobalContext)

    return (
        <BreakContainer>
            <BreakDescription>
                <BreakTitle>{texts.break.title[language]}</BreakTitle>
                <Button bg={colors.green} >
                    <Link to='contact'>
                        {texts.home.button1[language]}
                    </Link>
                </Button>
            </BreakDescription>
        </BreakContainer>
    )
}
